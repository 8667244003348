import React, { useContext } from 'react';
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AxisDomain } from 'recharts/types/util/types';
import { AppContext } from '../../context/AppContext';
import { getUnit } from '../../helpers/helpers';
import { ChartProps } from '../../interfaces/PropTypes';
import { darkTheme, lightTheme } from '../../styles/theme';

const Chart: React.FC<ChartProps> = ({ measurement, setting, data }) => {
  const { isDarkMode } = useContext(AppContext);
  const theme = isDarkMode ? darkTheme : lightTheme;

  const noRef = ['Usage', 'Head Tilt'];

  const domains: Record<string, AxisDomain> = {
    Usage: [0, 12],
    AHI: [0, 'auto'],
    SpO2: [60, 100],
    Temperature: [30, 50],
    'Head Tilt': [-90, 90],
  };

  if (!setting) return <></>;

  return (
    <ResponsiveContainer height={250} width="100%">
      <LineChart data={data} margin={{ right: 50, left: 30 }}>
        <CartesianGrid strokeDasharray="3 3" />
        {!noRef.includes(measurement) && (
          <>
            <ReferenceLine y={setting.primaryValue} label="Moderate" stroke="orange" strokeDasharray="3 3" />
            <ReferenceLine y={setting.secondaryValue as string} label="Severe" stroke="red" strokeDasharray="3 3" />
          </>
        )}
        <XAxis dataKey="time" />
        <YAxis type="number" domain={domains[measurement]} />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="data"
          name={measurement}
          stroke={theme.palette.primary.main}
          unit={getUnit(measurement)}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
