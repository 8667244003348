import { Accordion, AccordionDetails, Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { DateTime, Interval } from 'luxon';
import React, { useState } from 'react';
import { twoDP } from '../../helpers/helpers';
import { IData, IDataFinal, IDataTransformed } from '../../interfaces/interfaces';
import { PatientDataProps } from '../../interfaces/PropTypes';
import { StyledPatientButton, StyledTypography } from '../../styles/styles';
import Alert from './Alert';
import Chart from './Chart';
import Note from './Note';
import PatientAccordionSummary from './PatientAccordionSummary';
import Summary from './Summary';

const PatientDataContainer = styled(Box)`
  margin-bottom: 5vh;
`;

const FiltersContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin: 1.5vh 0;
`;

const StyledFilterButton = styled(Button)`
  margin: 0 0.25vw;
`;

const StyledAccordion = styled(Accordion)`
  margin-bottom: 2vh;
  border: ${({ theme }) => `1px solid ${theme.palette.primary.dark}`};
  border-radius: 5px;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 425px;
  align-self: flex-end;
`;

const PatientData: React.FC<PatientDataProps> = ({
  alerts,
  data,
  notes,
  settings,
  handleMarkAlertResolved,
  handleEditNote,
  handleOpenDialog,
  handleToggleContent,
}) => {
  const [currRange, setCurrRange] = useState('week');

  // Generate the timestamp which corresponds to each usage measurement
  const transformUsage: IDataTransformed[] = data
    .filter((item) => item.measurement === 'usage')
    .map((item) => ({
      time: DateTime.fromISO(item.start),
      data: parseInt(item.dataPoints),
    }));

  // Generate the timestamp which corresponds to each data point
  const transformData = (measurement: string, data: IData[]) => {
    return data
      .filter((item) => item.measurement === measurement)
      .map((item) => {
        const dataPoints: number[] = JSON.parse(item.dataPoints);
        const transformedDataPoints: IDataTransformed[] = dataPoints.map((data, idx) => ({
          time: DateTime.fromISO(item.start).plus({ seconds: idx * item.sampleRate }),
          data: data,
        }));

        return transformedDataPoints;
      })
      .flat();
  };

  // Get the timestamp which corresponds to the first data point to show on the graph
  const getLowerBound = (time: DateTime) => {
    let diff: Duration = { hours: 0 };
    if (currRange === 'week') {
      diff = { days: 7 };
    } else if (currRange === 'month') {
      diff = { months: 1 };
    }

    return time.minus(diff);
  };

  // Given a timestamp, get the time which corresponds to the end of the desired interval
  // of data to compress into one data point
  const getUpperBound = (time: DateTime) => {
    return time.plus({ days: 1 });
  };

  // Condense each day's data points into one data point
  const condenseData = (data: IDataTransformed[]) => {
    if (!data.length) return data.map((d) => ({ ...d, time: DateTime.now() } as IDataTransformed));

    // Exclude any data points that are too far in the past
    let lowerBound = getLowerBound(DateTime.now());
    const filteredData = data.filter((item) => item.time >= lowerBound);

    // The final condensed data
    const condensedData: IDataTransformed[] = [];

    // The data for the current day
    const currInterval: IDataTransformed[] = [];

    // Collect each day's data points into currInterval
    // Update upperBound when a new day's data is reached
    let upperBound = getUpperBound(data[0].time);
    for (const d of filteredData) {
      if (d.time < upperBound) {
        currInterval.push(d);
      } else {
        lowerBound = d.time;
        upperBound = getUpperBound(d.time);

        if (currInterval.length) condensedData.push(condenseInterval(currInterval));

        currInterval.length = 0;
        currInterval.push(d);
      }
    }

    // Condense the latest day's data
    if (currInterval.length > 0) condensedData.push(condenseInterval(currInterval));

    return condensedData;
  };

  // Data is condensed by calculating the average of the day's data
  const condenseInterval = (currInterval: IDataTransformed[]): IDataTransformed => {
    const sum = currInterval.reduce((total, curr) => total + curr.data, 0);

    return {
      time: currInterval[0].time,
      data: twoDP(sum / currInterval.length),
    } as IDataTransformed;
  };

  // If there are days with no data, insert a data point with null value to ensure that
  // the graph renders an empty data point on that day
  const fillGaps = (data: IDataTransformed[]) => {
    if (!data.length) return [];

    const newData: IDataTransformed[] = [];

    const diff = { days: 1 };

    for (let i = 0; i < data.length - 1; i++) {
      const curr = data.at(i);
      const next = data.at(i + 1);

      if (!curr || !next) break;

      newData.push(curr);

      // Find gap
      const interval = Interval.after(curr.time, diff);
      if (interval.contains(next.time)) continue;

      // Insert blank data - MIN_SAFE_INTEGER is used because of type constraints
      for (let dummyTime = curr.time.plus(diff); dummyTime < next.time; dummyTime = dummyTime.plus(diff)) {
        newData.push({ time: dummyTime, data: Number.MIN_SAFE_INTEGER });
      }
    }

    if (data.length > 0) newData.push(data.at(-1)!);

    // Generate the null data
    return newData.map(
      (data) =>
        ({
          data: data.data === Number.MIN_SAFE_INTEGER ? null : data.data,
          time: dateTimeToString(data.time),
        } as IDataFinal)
    );
  };

  const dateTimeToString = (datetime: DateTime) => {
    if (currRange === 'week') {
      return datetime.toLocaleString(DateTime.DATETIME_SHORT);
    } else if (currRange === 'month') {
      return datetime.toLocaleString(DateTime.DATE_SHORT);
    }

    return datetime.toLocaleString(DateTime.DATE_SHORT);
  };

  const handleChangeRange = (newRange: string) => {
    setCurrRange(newRange);
  };

  const getAlertLevel = (alertName: string) =>
    alerts.find((alert) => alert.alertName === alertName && alert.alertLevel > 0) !== undefined;

  const usageData = fillGaps(condenseData(transformUsage));
  const ahiData = fillGaps(condenseData(transformData('ahi', data)));
  const spo2Data = fillGaps(condenseData(transformData('spo2', data)));
  const tempData = fillGaps(condenseData(transformData('temp', data)));
  const tiltData = fillGaps(condenseData(transformData('tilt', data)));

  return (
    <>
      <PatientDataContainer>
        <FiltersContainer>
          <StyledTypography>View data for:</StyledTypography>
          <StyledFilterButton
            variant={currRange === 'week' ? 'outlined' : 'text'}
            onClick={() => handleChangeRange('week')}
          >
            Past Week
          </StyledFilterButton>
          <StyledFilterButton
            variant={currRange === 'month' ? 'outlined' : 'text'}
            onClick={() => handleChangeRange('month')}
          >
            Past Month
          </StyledFilterButton>
        </FiltersContainer>
        <StyledAccordion defaultExpanded={true}>
          <PatientAccordionSummary title="Summary" id="Summary" isNote={true} />
          <AccordionDetails>
            <Summary
              currRange={currRange}
              usage={usageData}
              ahi={ahiData}
              spo2={spo2Data}
              temp={tempData}
              tilt={tiltData}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion defaultExpanded={true}>
          <PatientAccordionSummary title="Usage" id="usage" hasAlert={getAlertLevel('usage')} />
          <AccordionDetails>
            <Chart measurement="Usage" setting={settings['usage']} data={usageData} />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion defaultExpanded={true}>
          <PatientAccordionSummary title="AHI" id="ahi" hasAlert={getAlertLevel('ahi')} />
          <AccordionDetails>
            <Chart measurement="AHI" setting={settings['ahi']} data={ahiData} />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion defaultExpanded={true}>
          <PatientAccordionSummary title="SpO2" id="spo2" hasAlert={getAlertLevel('spo2')} />
          <Chart measurement="SpO2" setting={settings['spo2']} data={spo2Data} />
        </StyledAccordion>
        <StyledAccordion defaultExpanded={true}>
          <PatientAccordionSummary title="Temperature" id="temperature" hasAlert={getAlertLevel('temp')} />
          <AccordionDetails>
            <Chart measurement="Temperature" setting={settings['temp']} data={tempData} />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion defaultExpanded={true}>
          <PatientAccordionSummary title="Head Tilt" id="tilt" hasAlert={getAlertLevel('tilt')} />
          <AccordionDetails>
            <Chart measurement="Head Tilt" setting={settings['tilt']} data={tiltData} />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion defaultExpanded={true}>
          <PatientAccordionSummary title="Clinical Notes" id="notes" isNote={true} />
          <AccordionDetails>
            {notes.length > 0
              ? notes.map((note) => <Note note={note} handleEditNote={handleEditNote} />)
              : 'No clinical notes found'}
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion defaultExpanded={true}>
          <PatientAccordionSummary title="Alerts" id="alerts" isNote={true} />
          <AccordionDetails>
            {alerts.length > 0
              ? alerts
                  .map((alert) => <Alert alert={alert} handleMarkAlertResolved={handleMarkAlertResolved} />)
                  .reverse()
              : 'No alerts found'}
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion defaultExpanded={true}>
          <PatientAccordionSummary title="Device Alerts" id="deviceAlerts" isNote={true} />
          <AccordionDetails>Device alerts will go in here</AccordionDetails>
        </StyledAccordion>
      </PatientDataContainer>
      <ButtonsContainer>
        <StyledPatientButton variant="contained" onClick={() => handleOpenDialog('createNote')}>
          Add Note
        </StyledPatientButton>
        <StyledPatientButton variant="contained" onClick={handleToggleContent}>
          Notification Settings
        </StyledPatientButton>
      </ButtonsContainer>
    </>
  );
};

export default PatientData;
