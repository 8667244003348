import { FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import apiClient from '../../api/axiosConfig';
import { AppContext } from '../../context/AppContext';
import { userCreateSchema } from '../../helpers/formValidationSchema';
import { handleError } from '../../helpers/helpers';
import { ICareGroup, IUserRegister } from '../../interfaces/interfaces';
import { RegisterUserFormProps } from '../../interfaces/PropTypes';
import { RegisterRes, UserCareGroupRes } from '../../interfaces/responses';
import {
  ComplexFieldsContainer,
  FormContainer,
  StyledFormField,
  StyledSelect,
  StyledTextField,
} from '../../styles/formStyles';
import FormButtons from './FormButtonsContainer';

const StyledInputLabel = styled(InputLabel)`
  margin-top: 1vh;
`;

const RegisterUserForm: React.FC<RegisterUserFormProps> = ({ role, setPopupOpen, loadData }) => {
  const [birthday, setBirthday] = useState<DateTime | null>(DateTime.now());
  const [currCareGroupIndex, setCurrCareGroupIndex] = useState('');
  const [careGroups, setCareGroups] = useState<ICareGroup[]>([]);

  const { currUser, setErrorMessage, setErrorVisible } = useContext(AppContext);

  useEffect(() => {
    apiClient
      .get<UserCareGroupRes>(`/caregroup/user/${currUser.userID}`)
      .then((res) => setCareGroups(res.data.careGroups));
    // eslint-disable-next-line
  }, []);

  const initialValues: IUserRegister = {
    firstName: '',
    lastName: '',
    gender: 'male',
    birthday: DateTime.now().toLocaleString(DateTime.DATE_SHORT),
    role: role,
    phone: '',
    email: '',
  };

  const handleChangeBirthday = (newBirthday: Date | null) => {
    setBirthday(newBirthday ? DateTime.fromJSDate(newBirthday) : null);
  };

  const handleChangeCareGroup = (event: SelectChangeEvent) => {
    setCurrCareGroupIndex(event.target.value as string);
  };

  const handleFormSubmit = async (values: IUserRegister, { setSubmitting }: FormikHelpers<IUserRegister>) => {
    if (!currCareGroupIndex && values.role === 'patient') {
      setErrorMessage('Please select a care group');
      setErrorVisible(true);
      return;
    }

    setSubmitting(true);
    values.birthday = birthday
      ? birthday.toLocaleString(DateTime.DATE_SHORT)
      : DateTime.now().toLocaleString(DateTime.DATE_SHORT);

    try {
      const registerRes = await apiClient.post<RegisterRes>('/register', values);

      if (role === 'patient') {
        await apiClient.put('/careGroup', {
          userID: registerRes.data.userID,
          careGroupID: careGroups[parseInt(currCareGroupIndex)].groupID,
        });
      }

      setPopupOpen(false);
      loadData();
    } catch (err) {
      handleError(err, setErrorMessage, setErrorVisible);
    }

    setSubmitting(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik initialValues={initialValues} validationSchema={userCreateSchema} onSubmit={handleFormSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormContainer>
              <Field component={StyledFormField} name="firstName" label="First name" />
              <Field component={StyledFormField} name="lastName" label="Last name" />
              <Field component={StyledFormField} name="email" label="Email address" />
              <Field component={StyledFormField} name="phone" label="Phone number" />
              <ComplexFieldsContainer>
                <Field component={StyledSelect} name="gender" label="Gender">
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Indeterminate/Other</MenuItem>
                  <MenuItem value="notstated">Not Stated</MenuItem>
                </Field>
                {role === 'patient' && (
                  <>
                    <DatePicker
                      label="Birthday"
                      inputFormat="dd/MM/yyyy"
                      value={birthday}
                      onChange={handleChangeBirthday}
                      renderInput={(params) => <StyledTextField {...params} />}
                    />
                    <FormControl fullWidth>
                      <StyledInputLabel id="care-group-select">Care Group</StyledInputLabel>
                      <Select
                        labelId="care-group-select"
                        label="Care Group"
                        value={currCareGroupIndex}
                        onChange={handleChangeCareGroup}
                        placeholder="Care Group"
                        sx={{ margin: '1vh 0' }}
                      >
                        {careGroups.map((careGroup, index) => (
                          <MenuItem key={index} value={index.toString()}>
                            {careGroup.groupName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </ComplexFieldsContainer>
              {isSubmitting && <LinearProgress />}
              <FormButtons
                isSubmitting={isSubmitting}
                cancelCallback={() => setPopupOpen(false)}
                submitForm={submitForm}
                submitText={`Add ${role}`}
              />
            </FormContainer>
          </Form>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

export default RegisterUserForm;
