import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from './config';

const apiClient = axios.create({ baseURL: API_URL });

const onRequest = (config: AxiosRequestConfig) => {
  if (config.headers === undefined) config.headers = {};

  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

  return config;
};

apiClient.interceptors.request.use(onRequest);

export default apiClient;
