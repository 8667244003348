import jwtDecode, { JwtPayload } from 'jwt-decode';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiClient from '../api/axiosConfig';
import { AppContext } from '../context/AppContext';
import { logoutHandler } from '../helpers/authHelpers';
import { handleError } from '../helpers/helpers';
import { LoginRes, UserRes } from '../interfaces/responses';

const CallbackHandler: React.FC = () => {
  const { setToken, currUser, setCurrUser, setErrorMessage, setErrorVisible } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const authorizationCode = searchParams.get('code');

    if (authorizationCode === null) {
      navigate('/');
    } else {
      apiClient
        .post<LoginRes>('/login', { authorizationCode })
        .then((res) => {
          const { access_token, id_token } = res.data;
          localStorage.setItem('token', access_token);
          setToken(access_token);

          const auth0Id = jwtDecode<JwtPayload>(id_token).sub!.split('|')[1];
          return apiClient.get<UserRes>(`/user/staff/${auth0Id}`);
        })
        .then((res) => {
          setCurrUser({ ...currUser, ...res.data.userData });
          navigate('/home');
        })
        .catch((err) => {
          handleError(err, setErrorMessage, setErrorVisible);
          setToken('');
          return logoutHandler(navigate);
        })
        .catch((err) => handleError(err, setErrorMessage, setErrorVisible));
    }
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default CallbackHandler;
