import { createTheme } from '@mui/material';

export interface ThemeType {
  palette: {
    mode: string | undefined;
    primary: {
      main: string;
      light: string;
      dark: string;
    };
    secondary: {
      main: string;
    };
    background: {
      default: string;
    };
  };
}

const baseTheme = ({
  mode,
  background,
  light,
  dark,
}: {
  mode: 'light' | 'dark' | undefined;
  background: string;
  light: string;
  dark: string;
}) => ({
  palette: {
    mode: mode,
    primary: {
      main: '#1A737F',
      light: light,
      dark: dark,
    },
    secondary: {
      main: '#1A8694',
    },
    background: {
      default: background,
    },
  },
});

export const lightTheme = createTheme(
  baseTheme({
    mode: 'light',
    background: '#fafafa',
    light: '#fafafa',
    dark: '#181818',
  })
);

export const darkTheme = createTheme(
  baseTheme({
    mode: 'dark',
    background: '#212121',
    light: '#181818',
    dark: '#fafafa',
  })
);
