enum Env {
  DEV = 'development',
  MOCK = 'mock',
  PROD = 'production',
}

const LOCAL = 'http://localhost:8080';
const LIVE = 'https://api.optosleep-dev.ihealthe.org';

const API_CONFIG: Record<string, string> = Object.freeze({
  [Env.DEV]: LOCAL,
  [Env.MOCK]: LIVE,
  [Env.PROD]: LIVE,
});

export const API_URL = API_CONFIG[process.env.REACT_APP_ENVIRONMENT || Env.DEV];
