import { LinearProgress, MenuItem } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import apiClient from '../../api/axiosConfig';
import { AppContext } from '../../context/AppContext';
import { userEditSchema } from '../../helpers/formValidationSchema';
import { handleError } from '../../helpers/helpers';
import { IUserEdit } from '../../interfaces/interfaces';
import { EditPatientAccountFormProps } from '../../interfaces/PropTypes';
import {
  ComplexFieldsContainer,
  FormContainer,
  StyledFormField,
  StyledSelect,
  StyledTextField,
} from '../../styles/formStyles';
import FormButtons from './FormButtonsContainer';

const EditPatientAccountForm: React.FC<EditPatientAccountFormProps> = ({ patient, setPopupOpen, loadData }) => {
  const [birthday, setBirthday] = useState<DateTime | null>(DateTime.fromISO(patient.birthday));

  const { setErrorMessage, setErrorVisible } = useContext(AppContext);

  const { userID, gender, phone, email } = patient;

  const initialValues: IUserEdit = {
    userID,
    gender,
    birthday: patient.birthday,
    phone,
    email,
  };

  const handleChangeBirthday = (newBirthday: Date | null) => {
    setBirthday(newBirthday ? DateTime.fromJSDate(newBirthday) : null);
  };

  const handleFormSubmit = (values: IUserEdit, { setSubmitting }: FormikHelpers<IUserEdit>) => {
    setSubmitting(true);
    values.birthday = birthday
      ? birthday.toLocaleString(DateTime.DATE_SHORT)
      : DateTime.now().toLocaleString(DateTime.DATE_SHORT);

    apiClient
      .put('/user', values)
      .then(() => {
        loadData();
        setPopupOpen(false);
      })
      .catch((err) => handleError(err, setErrorMessage, setErrorVisible))
      .finally(() => setSubmitting(false));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik initialValues={initialValues} validationSchema={userEditSchema} onSubmit={handleFormSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormContainer>
              <Field component={StyledFormField} name="email" label="Email address" />
              <Field component={StyledFormField} name="phone" label="Phone number" />
              <ComplexFieldsContainer>
                <Field component={StyledSelect} name="gender" label="Gender">
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Indeterminate/Other</MenuItem>
                  <MenuItem value="notstated">Not Stated</MenuItem>
                </Field>
                <DatePicker
                  label="Birthday"
                  inputFormat="dd/MM/yyyy"
                  value={birthday}
                  onChange={handleChangeBirthday}
                  renderInput={(params) => <StyledTextField {...params} />}
                />
              </ComplexFieldsContainer>
            </FormContainer>
            {isSubmitting && <LinearProgress />}
            <FormButtons
              isSubmitting={isSubmitting}
              cancelCallback={() => setPopupOpen(false)}
              submitForm={submitForm}
              submitText="Save Changes"
            />
          </Form>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

export default EditPatientAccountForm;
