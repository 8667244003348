import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../api/axiosConfig';
import { AppContext } from '../../context/AppContext';
import { getName, handleError } from '../../helpers/helpers';
import { IUser } from '../../interfaces/interfaces';

const StyledOption = styled('div')`
  padding: 1vh 1vw;
  &:hover {
    cursor: pointer;
  }
`;

const SearchBar: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const { setErrorMessage, setErrorVisible } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    apiClient
      .get<IUser[]>('/user')
      .then((res) => setUsers(res.data.filter((user) => user.role === 'patient')))
      .catch((err) => handleError(err, setErrorMessage, setErrorVisible));
    // eslint-disable-next-line
  }, []);

  return (
    <Autocomplete
      options={users}
      isOptionEqualToValue={(option, value) => option.userID === value.userID}
      getOptionLabel={(option) => getName(option)}
      renderOption={(props, option, { selected }) => (
        <StyledOption onClick={() => navigate(`/patient/${option.userID}`)}>{getName(option)}</StyledOption>
      )}
      renderInput={(params) => <TextField {...params} label="Patient name" />}
      sx={{ width: '300px' }}
    />
  );
};

export default SearchBar;
