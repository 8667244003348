import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { ProtectedRouteProps } from '../interfaces/PropTypes';

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { token } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate('/', { replace: true });
    // eslint-disable-next-line
  }, []);

  return children;
};

export default ProtectedRoute;
