import { Alert, GlobalStyles, Snackbar, ThemeProvider } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppContext } from './context/AppContext';
import Admin from './pages/Admin';
import CallbackHandler from './pages/CallbackHandler';
import PatientsOverview from './pages/PatientsOverview';
import LoginRedirect from './pages/LoginRedirect';
import Logout from './pages/Logout';
import Patient from './pages/Patient';
import Profile from './pages/Profile';
import AdminProtectedRoute from './routes/AdminProtectedRoute';
import ProtectedRoute from './routes/ProtectedRoute';
import { darkTheme, lightTheme } from './styles/theme';

const App: React.FC = () => {
  const { setToken, isDarkMode, errorMessage, errorVisible, setErrorVisible } = useContext(AppContext);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) setToken(token);
    // eslint-disable-next-line
  }, []);

  const handleErrorClose = () => {
    setErrorVisible(false);
  };

  const theme = isDarkMode ? darkTheme : lightTheme;
  const globalStyle = {
    body: {
      background: theme.palette.background.default,
      transition: 'background 0.2s',
    },
    '::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '::-webkit-scrollbar-track': {
      background: theme.palette.background.default,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.secondary.dark,
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={globalStyle} />
      <BrowserRouter>
        <Routes>
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <PatientsOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/patient/:patientID"
            element={
              <ProtectedRoute>
                <Patient />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <AdminProtectedRoute>
                <Admin />
              </AdminProtectedRoute>
            }
          />
          <Route path="/callback" element={<CallbackHandler />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<LoginRedirect />} />
        </Routes>
      </BrowserRouter>
      <Snackbar
        open={errorVisible}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={handleErrorClose}
      >
        <Alert severity="error" onClose={handleErrorClose} variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default App;
