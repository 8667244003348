import { DateTime } from 'luxon';
import React, { useState } from 'react';
import apiClient from '../api/axiosConfig';
import FabFormDialog from '../components/forms/FabFormDialog';
import Header from '../components/Header';
import PatientOverview from '../components/patientOverview/PatientOverview';
import { IAlert, IPatientData, IPatientDataRaw } from '../interfaces/interfaces';
import { AlertsRes, DataSummaryRes, UserCareGroupsRes } from '../interfaces/responses';
import { CenteredContainer } from '../styles/styles';

const PatientsOverview: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState<IPatientData[]>([]);

  const loadPatients = () => {
    setIsLoading(true);

    apiClient
      .get<DataSummaryRes[]>('/data')
      .then((dataSummary) => {
        const rowsRaw: IPatientDataRaw[] = dataSummary.data.map((data) => ({ ...data, careGroups: [] }));
        const careGroupPromises = rowsRaw.map((row) =>
          apiClient.get<UserCareGroupsRes>(`/caregroup/user/${row.userID}`)
        );
        const alertPromises = rowsRaw.map((row) => apiClient.get<AlertsRes>(`/alerts/${row.userID}`));

        Promise.all(careGroupPromises).then((careGroupRes) => {
          Promise.all(alertPromises).then((alertRes) => {
            const rows: IPatientData[] = rowsRaw.map((row, index) => {
              const alerts: IAlert[] = alertRes[index].data.alerts;
              const alertsUpdatedOn: string[] = [];

              for (const alert of alerts) {
                if (alert.creationDate !== alert.updatedOn) alertsUpdatedOn.push(alert.updatedOn);
              }

              const latestUpdated = alertsUpdatedOn.length ? alertsUpdatedOn.reduce((a, b) => (a > b ? a : b)) : 'n/a';

              return {
                ...row,
                careGroups: careGroupRes[index].data.careGroups.map((careGroup) => careGroup.groupName).join(','),
                lastReviewed:
                  latestUpdated === 'n/a'
                    ? latestUpdated
                    : DateTime.fromISO(latestUpdated).toLocaleString(DateTime.DATETIME_SHORT),
              };
            });
            setPatients(
              rows.filter((row, index, self) => index === self.findIndex((found) => found.userID === row.userID))
            );
          });
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Header />
      <CenteredContainer>
        <PatientOverview isLoading={isLoading} patients={patients} loadPatients={loadPatients} />
        <FabFormDialog fabText="Add New Patient" formName="patient" loadData={loadPatients} />
      </CenteredContainer>
    </>
  );
};

export default PatientsOverview;
