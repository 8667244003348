import { LinearProgress } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useContext } from 'react';
import apiClient from '../../api/axiosConfig';
import { AppContext } from '../../context/AppContext';
import { careGroupCreateSchema } from '../../helpers/formValidationSchema';
import { handleError } from '../../helpers/helpers';
import { ICareGroupCreate } from '../../interfaces/interfaces';
import { CreateCareGroupFormProps } from '../../interfaces/PropTypes';
import { FormContainer, StyledFormField } from '../../styles/formStyles';
import FormButtons from './FormButtonsContainer';

const CreateCareGroupForm: React.FC<CreateCareGroupFormProps> = ({ setPopupOpen, loadData }) => {
  const { setErrorMessage, setErrorVisible } = useContext(AppContext);

  const initialValues: ICareGroupCreate = {
    groupName: '',
  };

  const handleFormSubmit = (values: ICareGroupCreate, { setSubmitting }: FormikHelpers<ICareGroupCreate>) => {
    setSubmitting(true);
    apiClient
      .post('/caregroup', values)
      .then(() => {
        if (setPopupOpen) setPopupOpen(false);
        if (loadData) loadData();
      })
      .catch((err) => handleError(err, setErrorMessage, setErrorVisible))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={careGroupCreateSchema} onSubmit={handleFormSubmit}>
      {({ submitForm, isSubmitting }) => (
        <Form>
          <FormContainer>
            <Field component={StyledFormField} name="groupName" label="Group Name" />
            {isSubmitting && <LinearProgress />}
            <FormButtons
              isSubmitting={isSubmitting}
              cancelCallback={() => setPopupOpen(false)}
              submitForm={submitForm}
              submitText="Create"
            />
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
};

export default CreateCareGroupForm;
