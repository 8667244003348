import { styled } from '@mui/system';
import React from 'react';
import { ReasonTextfieldProps } from '../../interfaces/PropTypes';
import { StyledTextField } from '../../styles/formStyles';

const ReasonField = styled(StyledTextField)`
  margin-bottom: 2vh;
`;

const ReasonTextfield: React.FC<ReasonTextfieldProps> = (props) => (
  <ReasonField {...props} placeholder="Reason for change" fullWidth multiline minRows={3} />
);

export default ReasonTextfield;
