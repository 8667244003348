import React from 'react';
import { FormButtonsContainerProps } from '../../interfaces/PropTypes';
import { CancelButton, ConfirmButton, FormButtonsContainer } from '../../styles/formStyles';

const FormButtons: React.FC<FormButtonsContainerProps> = ({ isSubmitting, cancelCallback, submitForm, submitText }) => {
  return (
    <FormButtonsContainer>
      <CancelButton variant="outlined" disabled={isSubmitting} onClick={cancelCallback}>
        Cancel
      </CancelButton>
      <ConfirmButton variant="contained" disabled={isSubmitting} onClick={submitForm}>
        {submitText}
      </ConfirmButton>
    </FormButtonsContainer>
  );
};

export default FormButtons;
