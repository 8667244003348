import { AxiosError } from 'axios';
import { IUser } from '../interfaces/interfaces';

export const capitaliseFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const getName = (u: IUser | undefined) => (u ? `${u.firstName} ${u.lastName}` : '');

export const handleError = (
  err: any,
  setErrorMessage: (newErrorMessage: string) => void,
  setErrorVisible: (isVisible: boolean) => void
) => {
  if (err instanceof AxiosError) {
    setErrorMessage(err.response?.data.message);
  } else {
    if (err.hasOwnProperty('message')) {
      setErrorMessage(err.message);
    } else {
      setErrorMessage(JSON.stringify(err));
    }
  }

  setErrorVisible(true);
};

export const stringToColour = (s: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < s.length; i += 1) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name: string, isProfile?: boolean) => ({
  sx: {
    bgcolor: stringToColour(name),
    color: '#000000',
    p: isProfile ? '2.5vh' : 1,
    fontSize: isProfile ? '30px' : '20px',
  },
  children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
});

export const getUnit = (measurement: string) => {
  const units: Record<string, string> = {
    Usage: ' hour(s)',
    AHI: ' event(s)/hour',
    SpO2: '%',
    Temperature: '°C',
    'Head Tilt': '°',
  };

  return units[measurement];
};

export const twoDP = (x: number | null) => (x ? parseFloat(x.toFixed(2)) : x);
