import React, { useEffect } from 'react';
import { LOGIN_URL } from '../helpers/authHelpers';

const LoginRedirect: React.FC = () => {
  useEffect(() => {
    window.location.replace(LOGIN_URL);
  }, []);

  return <></>;
};

export default LoginRedirect;
