import { Box, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import React, { useContext } from 'react';
import apiClient from '../../api/axiosConfig';
import { AppContext } from '../../context/AppContext';
import { clinicalNoteCreateSchema } from '../../helpers/formValidationSchema';
import { handleError } from '../../helpers/helpers';
import { INoteCreate } from '../../interfaces/interfaces';
import { NoteFormProps } from '../../interfaces/PropTypes';
import { StyledFormField } from '../../styles/formStyles';
import FormButtons from './FormButtonsContainer';

const FormContainer = styled(Box)`
  width: 20vw;
`;

const NoteForm: React.FC<NoteFormProps> = ({ patientID, setPopupOpen, loadData, noteID, desc, isEdit }) => {
  const { currUser, setErrorMessage, setErrorVisible } = useContext(AppContext);

  const initialValues: INoteCreate = {
    patientID,
    clinicianID: currUser.userID,
    description: isEdit ? desc! : '',
  };

  const handleFormSubmit = (values: INoteCreate, { setSubmitting }: FormikHelpers<INoteCreate>) => {
    setSubmitting(true);

    const request = isEdit
      ? apiClient.put('/notes', { noteID, description: values.description })
      : apiClient.post('/notes', values);

    request
      .then(() => {
        loadData();
        setPopupOpen(false);
      })
      .catch((err) => handleError(err, setErrorMessage, setErrorVisible))
      .finally(() => setSubmitting(false));
  };

  return (
    <FormContainer>
      <Formik initialValues={initialValues} validationSchema={clinicalNoteCreateSchema} onSubmit={handleFormSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field name="description" label="Description">
              {(fieldProps: FieldProps) => <StyledFormField {...fieldProps} fullWidth multiline minRows={3} />}
            </Field>
            {isSubmitting && <LinearProgress />}
            <FormButtons
              isSubmitting={isSubmitting}
              cancelCallback={() => setPopupOpen(false)}
              submitForm={submitForm}
              submitText={isEdit ? 'Edit Note' : 'Create Note'}
            />
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

export default NoteForm;
