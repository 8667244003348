import React, { createContext, useState } from 'react';
import { IUser } from '../interfaces/interfaces';
import { AppContextProviderProps } from '../interfaces/PropTypes';

const defaultUser: IUser = {
  auth0ID: '',
  userID: 0,
  firstName: '',
  lastName: '',
  gender: '',
  birthday: '',
  phone: '',
  email: '',
  isActive: true,
  role: '',
};

export interface IAppContext {
  token: string;
  setToken: (newToken: string) => void;
  currUser: IUser;
  setCurrUser: (newUser: IUser) => void;
  isDarkMode: boolean;
  setIsDarkMode: (newIsDarkMode: boolean) => void;
  errorMessage: string;
  setErrorMessage: (newErrorMessage: string) => void;
  errorVisible: boolean;
  setErrorVisible: (isVisible: boolean) => void;
}

export const AppContext = createContext<IAppContext>({
  token: '',
  setToken: () => {},
  currUser: defaultUser,
  setCurrUser: () => {},
  isDarkMode: false,
  setIsDarkMode: () => {},
  errorMessage: '',
  setErrorMessage: () => {},
  errorVisible: false,
  setErrorVisible: () => {},
});

const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [token, setToken] = useState('');
  const [currUser, setCurrUser] = useState(defaultUser);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);

  const initialContext: IAppContext = {
    token,
    setToken,
    currUser,
    setCurrUser,
    isDarkMode,
    setIsDarkMode,
    errorMessage,
    setErrorMessage,
    errorVisible,
    setErrorVisible,
  };

  return <AppContext.Provider value={initialContext}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
