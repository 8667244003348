import { NavigateFunction } from 'react-router-dom';
import apiClient from '../api/axiosConfig';

const AUTH_URL = process.env.REACT_APP_AUTH_URL!;

const loginUrlParams: Record<string, string> = {
  response_type: 'code',
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID!,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI!,
  scope: 'openid profile',
  audience: process.env.REACT_APP_AUTH_AUDIENCE!,
};

const generateParams = (params: Record<string, string>) =>
  Object.entries(params)
    .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value!))
    .join('&');

export const LOGIN_URL = `${AUTH_URL}/authorize?` + generateParams(loginUrlParams);

export const logoutHandler = async (navigate: NavigateFunction) => {
  apiClient
    .get('/logout')
    .catch((err) => {
      throw err;
    })
    .finally(() => {
      localStorage.clear();
      navigate('/logout');
    });
};
