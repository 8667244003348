import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Box, styled } from '@mui/system';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { NoteProps } from '../../interfaces/PropTypes';
import { SmallerText, StyledContainer, StyledContents, StyledMetadata } from '../../styles/patientStyles';
import { StyledTypography } from '../../styles/styles';

const StyledNoteTitle = styled(StyledTypography)`
  font-weight: bold;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 1vw;
`;

const Note: React.FC<NoteProps> = ({ note, handleEditNote }) => {
  const { currUser } = useContext(AppContext);

  return (
    <StyledContainer>
      <StyledMetadata>
        <Box>
          <StyledNoteTitle>{`${note.clinician.userData.firstName} ${note.clinician.userData.lastName}`}</StyledNoteTitle>
          <SmallerText>
            {DateTime.fromISO(note.creationDate).toLocaleString(DateTime.DATE_SHORT)}
            {note.creationDate !== note.updatedOn && (
              <>, last edited: {DateTime.fromISO(note.updatedOn).toLocaleString(DateTime.DATE_SHORT)}</>
            )}
          </SmallerText>
        </Box>
        {note.clinicianID === currUser.userID && (
          <StyledIconButton onClick={() => handleEditNote(note.noteID, note.description)}>
            <Edit />
          </StyledIconButton>
        )}
      </StyledMetadata>
      <Box>
        <StyledContents>{note.description}</StyledContents>
      </Box>
    </StyledContainer>
  );
};

export default Note;
